import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 250px;
  background-color: #f2f2f2;
  position: fixed;
  top: 0;
  left: 0;
  li {
    list-style-type: none;
    text-decoration: none;
    text-decoration-line: none;
    list-style: none;
    color: #000;
    margin: 0px 0px 0px 0px;
  }
  a {
    text-decoration: none;
  }

  .email {
    font-size: 12px;
  }
`;

export const NavItem = styled.div`
  margin: 0px 10px 0px 10px;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #d4d4d4;
    border-radius: 0.5rem;
    border: 1px solid #d2d2d2;
  }
`;

export const Logo = styled.div`
  margin: 25px 10px 0px 25px;
  display: flex;
  justify-content: space-between;
  .back {
    width: 22px;
    height: 30px;
    transform: rotate(180deg);
  }
  .logo {
    width: 30px;
    height: 30px;
  }
`;

export const Head = styled.div`
  margin: 25px 10px 0px 25px;
  margin-top: 10px;
  font-weight: 900;
  font-size: 25px;
  color: #00264d;
  padding: 0px;
`;

export const Bar = styled.div`
  margin: 10px 0px 10px 0px;
  background: #d9d9d9;
  width: 250px;
  height: 3px;
`;

export const User = styled.div`
  margin: 25px 10px 0px 25px;
  font-weight: bold;
  font-size: 15px;
  margin-top: -5px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 15px;
    height: 15px;
  }
`;
