import { ButtonComp } from "./style";

interface ButtonProp {
  value: string;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button = ({ value, active, onClick }: ButtonProp) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <ButtonComp type="submit" onClick={handleClick}>
      {value}
    </ButtonComp>
  );
};

export default Button;
