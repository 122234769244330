import styled from "styled-components";

export const Container = styled.div`
  display: block;
  justify-content: center;
  margin: 30px 15px;
  padding: 0 0 2rem 2rem;
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
`;

export const InputFilter = styled.input`
  all: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 5px 0 15px 0;
  width: 100%;
  text-decoration: none;
  text-align: left;
`;

export const Search = styled.div`
  display: flex;
  align-items: flex-start;
  img {
    width: 25px;
    height: 25px;
    align-items: center;
    margin-top: 8px;
    margin-right: 10px;
  }
`;

export const Select = styled.select`
  background-color: #fff;
  border: 2px solid rgba(46, 46, 46, 0.15);
  border-radius: 0.5rem;
  min-width: 200px;
  min-height: 30px;
  height: 40px;
  font-size: 18px;

  &:focus {
    outline-color: rgba(0, 0, 0, 0.5);
  }
`;
