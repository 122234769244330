import "./style";

import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Bar, Container, NavItem } from "./style";
import HeaderSidebar from "../HeaderSidebar";

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("Error signing out: ", error);
  }
}
interface UserInfo {
  username: string | null;
  email: string | null;
  groups: string[] | null;
}

const Sidebar = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>({
    username: null,
    email: null,
    groups: null,
  });

  useEffect(() => {
    async function fetchUserInfo() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // console.log(user);
        setUserInfo({
          username: user.username,
          email: user.attributes.email,
          groups: user.signInUserSession.accessToken.payload["cognito:groups"],
        });
        console.log(userInfo);
      } catch (error) {
        console.log("Error fetching user info:", error);
      }
    }

    fetchUserInfo();
  }, []);

  if (!userInfo.groups) {
    return (
      <Container>
        <HeaderSidebar />

        <NavItem>
          <NavLink className="NavLink" to="/" onClick={signOut}>
            <li>Logout</li>
          </NavLink>
        </NavItem>
      </Container>
    );
  }

  if (userInfo.groups && userInfo.groups.includes("Manager")) {
    return (
      <Container>
        <HeaderSidebar />
        <NavItem>
          <NavLink className="NavLink" to="/new-product">
            <li>New Product</li>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="NavLink" to="/list-product">
            <li>List Product</li>
          </NavLink>
        </NavItem>
        <Bar></Bar>
        <NavItem>
          <NavLink className="NavLink" to="/new-carrier">
            <li>Carrier</li>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="NavLink" to="/new-campaign">
            <li>Campaign</li>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="NavLink" to="/new-assets">
            <li>Assets</li>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="NavLink" to="/new-core">
            <li>Core</li>
          </NavLink>
        </NavItem>
        <Bar></Bar>
        <NavItem>
          <NavLink className="NavLink" to="/pricing-new-product">
            <li>Register Product</li>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="NavLink" to="/pricing">
            <li>Pricing</li>
          </NavLink>
        </NavItem>
        <Bar></Bar>
        <NavItem>
          <a
            className="NavLink"
            href="http://localhost:3030/api-docs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li>API Docs</li>
          </a>
        </NavItem>
        <Bar></Bar>

        <NavItem>
          <NavLink className="NavLink" to="/" onClick={signOut}>
            <li>Logout</li>
          </NavLink>
        </NavItem>
      </Container>
    );
  }

  if (userInfo.groups && userInfo.groups.includes("Viewer")) {
    return (
      <Container>
        <HeaderSidebar />
        <NavItem>
          <NavLink className="NavLink" to="/list-product">
            <li>List Product</li>
          </NavLink>
        </NavItem>
        <Bar></Bar>

        <NavItem>
          <NavLink className="NavLink" to="/" onClick={signOut}>
            <li>Logout</li>
          </NavLink>
        </NavItem>
      </Container>
    );
  }

  if (userInfo.groups && userInfo.groups.includes("TradeMarketing")) {
    return (
      <Container>
        <HeaderSidebar />
        <NavItem>
          <NavLink className="NavLink" to="/approval">
            <li>Approval users</li>
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink className="NavLink" to="/pricing">
            <li>Pricing</li>
          </NavLink>
        </NavItem> */}
        <Bar></Bar>

        <NavItem>
          <NavLink className="NavLink" to="/" onClick={signOut}>
            <li>Logout</li>
          </NavLink>
        </NavItem>
      </Container>
    );
  }

  if (userInfo.groups && userInfo.groups.includes("Stores")) {
    return (
      <Container>
        <HeaderSidebar />
        <NavItem>
          <NavLink className="NavLink" to="/pricing-new-product">
            <li>Register Product</li>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="NavLink" to="/pricing">
            <li>Pricing</li>
          </NavLink>
        </NavItem>
        <Bar></Bar>

        <NavItem>
          <NavLink className="NavLink" to="/" onClick={signOut}>
            <li>Logout</li>
          </NavLink>
        </NavItem>
      </Container>
    );
  }

  return (
    <Container>
      <NavItem>
        <NavLink className="NavLink" to="/" onClick={signOut}>
          <li>Logout</li>
        </NavLink>
      </NavItem>
    </Container>
  );
};

export default Sidebar;
