import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

const withPrivateRoute = (Component, allowedGroups) => {
  return (props) => {
    const { user } = useAuthenticator((context) => [context.user]);

    if (!user) {
      return <Navigate to="/login" />;
    }

    const userGroups =
      user.signInUserSession.accessToken.payload["cognito:groups"] || [];

    console.log(userGroups);
    const isAuthorized = allowedGroups.some((group) =>
      userGroups.includes(group)
    );

    console.log(isAuthorized);
    if (!isAuthorized) {
      return <Navigate to="/unauthorized" />;
    }

    return <Component {...props} />;
  };
};

export default withPrivateRoute;
