import styled from "styled-components";

export const ButtonComp = styled.button`
  background-color: #000;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  border: none;
  min-width: 600px;
  min-height: 50px;
  width: 100%;
  cursor: pointer;
  margin: 1.5rem auto;
  display: block;
  &:hover {
    opacity: 80%;
  }
`;
