import { useState } from "react";
import { Container, Forms } from "./style";
import TextField from "../../components/TextField";
import Dropdown from "../../components/Dropdown";
import Button from "../../components/Button";

const PricingNewProduct = (props) => {
  const save = (event) => {
    event.preventDefault();
    props.registeredProducts({
      product,
      model,
      imei,
      storage,
    });
    console.log("Form sended => ", product, model, imei, storage);
  };

  const [product, setProduct] = useState("");
  const [model, setModel] = useState("");
  const [imei, setImei] = useState("");
  const [storage, setStorage] = useState("");
  const storageSelect = ["64gb", "128gb", "256gb", "512gb"];

  return (
    <Container>
      <h3>Register New Product</h3>
      <Forms>
        <div onSubmit={save} className="forms">
          <TextField
            required={true}
            label="Model "
            tooltip="ModelInfo"
            tooltipDescription="Insert the name of model. Ex: Moto Edge 40"
            placeholder="Enter the name of the Model"
            value={model}
            wordChanged={(value) => setModel(value)}
          />
          <TextField
            label="IMEI"
            type="number"
            tooltip="IMEIInfo"
            tooltipDescription="IMEI is a unique code with 15 numbers. Ex: 355747236310170"
            placeholder="Optional"
            value={imei}
            wordChanged={(value) => setImei(value)}
          />
          <Dropdown
            required={true}
            label="Storage"
            items={storageSelect}
            value={storage}
            wordChanged={(value) => setStorage(value)}
          />
          <Button value="Register" />
        </div>
      </Forms>
    </Container>
  );
};

export default PricingNewProduct;
