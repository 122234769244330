import { FlexItem, Modal } from "./style";

interface UploadProgressProps {
  name: string;
  progress: number;
  url: any;
}

const UploadProgress = ({ name, progress, url }: UploadProgressProps) => {
  // if (progress === 100) {
  //   return <></>;
  // }
  return (
    <Modal>
      <FlexItem>
        <p>Name: {name}</p>
        <progress value={progress} max={100}></progress>
        {/* <p>Progress: {progress}%</p> */}
        <a href={url}>LINK</a>
      </FlexItem>
    </Modal>
  );
};

export default UploadProgress;
