import styled from "styled-components";

export const Container = styled.div`
  margin-top: 18px;
  select {
    background-color: #fff;
    padding: 14px 30px 14px 25px;
    border: 2px solid rgba(46, 46, 46, 0.15);
    border-radius: 0.75rem;
    min-width: 336px;
    min-height: 49px;
    width: 100%;
    font-size: 20px;
    /* -webkit-appearance: none;
    appearance: none; */
  }

  select:focus {
    outline-color: rgba(0, 0, 0, 0.5);
  }

  select::placeholder {
    color: #d2d2d2;
  }

  label {
    display: block;
    margin-bottom: 8px;
    font-size: 20px;
  }
`;
