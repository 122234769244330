import styled from "styled-components";

export const FlexItem = styled.div`
  justify-content: space-between;
  display: inline-flex;
  width: 100%;
  @media screen and (max-width: 1366px) {
    display: flex;
    flex-wrap: wrap;

    > * {
      width: 50%;
      box-sizing: border-box;
      padding: 0 10px;
    }
  }
`;
