import styled from "styled-components";

export const Container = styled.main`
  font-size: larger;
  text-align: center;
  margin-top: 15px;
`;

export const Forms = styled.section`
  display: flex;
  justify-content: center;
  margin: 40px 0;

  .forms {
    font-size: 15px;
    text-align: justify;
    min-width: 70%;
    max-width: 80%;
    background-color: #fff;
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
`;

export const FlexItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    width: 50%;
  }

  @media screen and (max-width: 1366px) {
    > * {
      width: 100%;
    }
  }
`;
