import styled from "styled-components";

export const Container = styled.div`
  font-size: larger;
  margin-top: 15px;
  h3 {
    text-align: center;
  }
  h2 {
    margin: 10px 0 10px 0;
  }
`;

export const Forms = styled.section`
  display: flex;
  justify-content: center;
  margin: 40px 0;

  .forms {
    font-size: 15px;
    text-align: justify;
    min-width: 70%;
    max-width: 80%;
    background-color: #fff;
    border-radius: 20px;
    padding: 36px 64px;
    margin-bottom: 20px;
    display: block;
  }

  .flex-item {
    justify-content: space-between;
    display: inline-flex;
    width: 100%;
  }

  button {
    margin: 20px 0 0 0;
  }
`;
