import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import Form from "../../components/Forms";

const NewProduct = () => {
  const [products, setProducts] = useState([]);

  const newProductRegistered = (product) => {
    console.log(product);
    setProducts([...products, product]);
  };
  const [userInfo, setUserInfo] = useState({
    username: null,
    email: null,
    groups: null,
  });

  useEffect(() => {
    async function fetchUserInfo() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log(user);
        const groups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        setUserInfo({
          username: user.username,
          email: user.attributes.email,
          groups: groups || [],
        });
        console.log(userInfo);
      } catch (error) {
        console.log("Error fetching user info:", error);
      }
    }

    fetchUserInfo();
  }, []);
  return (
    <>
      {userInfo.groups == "TradeMarketing" ? (
        <div>
          <h2>401 Unauthorized</h2>
        </div>
      ) : (
        <div>
          <Form
            registeredProducts={(product) => newProductRegistered(product)}
          />
        </div>
      )}
    </>
  );
};

export default NewProduct;
