import styled from "styled-components";

export const Container = styled.div`
  margin-top: 18px;
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 20px;
  }

  input {
    min-width: 336px;
    min-height: 49px;
    width: 100%;
    border: 2px solid rgba(46, 46, 46, 0.15);
    font-size: 20px;
    padding: 14px 14px 14px 25px;
    border-radius: 0.75rem;
  }

  input:focus {
    outline-color: rgba(0, 0, 0, 0.5);
  }

  input::placeholder {
    color: #d2d2d2;
  }
`;
