import { useState } from "react";
import TextField from "../../components/TextField";
import { Container, Forms } from "./style";
import axios from "axios";
import UploadProgress from "../../components/UploadProgress";
import Button from "../../components/Button";
import InputFiles from "../../components/InputFile";

interface CoreType {
  coreID: string;
  name: string;
  url: string;
}

const Core: React.FC = () => {
  const [coreID, setCoreID] = useState<string>("");
  const [name, setName] = useState<string>("");
  let url: string;
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [urlModal, setUrlModal] = useState<string>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setName(e.target.files[0].name);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData: CoreType = {
      coreID: coreID,
      name: name,
      url: url,
    };

    if (file) {
      if (file.type === "application/vnd.android.package-archive") {
        const fileData = new FormData();
        fileData.append("newAPK", file);
        try {
          const response = await axios.post(
            "https://api.moto-demomode-cloud.com/preSign",
            formData
          );
          const preSignURL = response.data;

          const uploadS3 = await axios.put(preSignURL, fileData, {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadProgress(progress);
            },
          });
          if (uploadS3.status === 200) {
            window.alert("Upload succeeded");
          }

          const fileUploadedName = coreID + ".apk";

          const uploadedFileUrl: string =
            "https://server-demomode-bucket.s3.amazonaws.com/core/" +
            fileUploadedName;
          setUrlModal(uploadedFileUrl);

          const postCore: CoreType = {
            ...formData,
            url: uploadedFileUrl,
          };

          const uploadSuccess = await axios.post(
            "https://api.moto-demomode-cloud.com/core",
            postCore
          );

          console.log(uploadSuccess.data);
        } catch (error) {
          console.error("FormData Error:", error);
        }
      } else {
        window.alert("Upload Failed | File not supported");
      }
    }
  };

  return (
    <Container>
      <h3>New Core</h3>
      <Forms>
        <div className="forms">
          <h2>Enter product data</h2>
          <TextField
            required={true}
            label="Core ID"
            placeholder="Enter the Core ID"
            value={coreID}
            wordChanged={(any: any) => setCoreID(any)}
          />
          <InputFiles
            required={true}
            label="New APK"
            name="newAPK"
            id="newAPK"
            setFile={handleFileChange}
          />
          <Button
            value="Register"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleSubmit(e)
            }
          />
        </div>
      </Forms>
      {uploadProgress > 0 && (
        <UploadProgress name={name} progress={uploadProgress} url={urlModal} />
      )}
    </Container>
  );
};

export default Core;
