import { Container } from "./style";

const InputFiles = (props) => {
  function handleFile(event) {
    const selectedFiles = event.target.files;
    const fileList = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      fileList.push(selectedFiles[i]);
    }

    props.setFile(fileList);
    console.log(fileList);
  }

  return (
    <Container>
      <label htmlFor={props.id}>
        {props.label}
        <input
          name="inputFile"
          onChange={handleFile}
          required={props.required}
          type="file"
          id={props.id}
          accept=".zip, .mp4, .jpg, .jpeg, .png, .vnd.android.package-archive"
          multiple
        />
      </label>
    </Container>
  );
};

export default InputFiles;
