import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import InputFiles from "../../components/InputFile";
import { useState, useEffect } from "react";
import "../../components/Forms/form.css";
import { Container, FlexItem, Forms } from "./style";
import axios from "axios";

const NewAssets = (props) => {
  const [productNames, setProductNames] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  const [model, setModel] = useState("");
  const [sku, setSku] = useState("");
  const [os, setOS] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.moto-demomode-cloud.com/posts"
        );
        const products = response.data;
        const namesProduct = products.map((product) => product.product);
        setProductNames(namesProduct);
      } catch (error) {
        console.error("Error getting products:", error);
      }
    };

    fetchData();
  }, []);

  const save = (event) => {
    event.preventDefault();
    props.registeredProducts({
      selectedProduct,
      model,
      sku,
      os,
    });
    console.log("Form sended => ", selectedProduct, model, sku, os);
  };

  return (
    <Container>
      <h3>New Assets</h3>
      <Forms>
        <div onSubmit={save} className="forms">
          <h2>Enter the data in the selected product</h2>
          <Dropdown
            label="Product"
            items={productNames}
            value={selectedProduct}
            required={true}
            wordChanged={(value) => setSelectedProduct(value)}
          />
          <FlexItem>
            <InputFiles required={true} label="Media" id="media" />
            <InputFiles required={true} label="Screensaver" id="screensaver" />
          </FlexItem>
          <InputFiles required={true} label="Ksp" id="ksp" />
          <Button value="Register" />
        </div>
      </Forms>
    </Container>
  );
};

export default NewAssets;
