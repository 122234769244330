import { Container } from "./style";
import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface TextFieldProps {
  value: string;
  required?: boolean;
  label: string;
  tooltip?: string;
  tooltipDescription?: string;
  placeholder: string;
  wordChanged: any;
}

const TextArea = ({
  value,
  required = false,
  label,
  tooltipDescription,
  tooltip,
  placeholder,
  wordChanged,
}: TextFieldProps) => {
  const [jsonInput, setJsonInput] = useState("");
  const [validationResult, setValidationResult] = useState<string>("");

  const handleValidate = () => {
    try {
      const parsedJSON = JSON.parse(value); // Utilizando o valor fornecido como prop
      setValidationResult("JSON Valid!");
    } catch (error) {
      console.error("JSON Error:", error);
      setValidationResult("JSON Invalid!");
    }
  };
  return (
    <Container>
      <label>
        {label}
        <span
          data-tooltip-id={tooltip}
          style={{
            cursor: "pointer",
          }}
        >
          (?)
        </span>
        <ReactTooltip
          id={tooltip}
          style={{
            marginLeft: "250px",
          }}
          place="top"
          variant="dark"
          content={tooltipDescription}
        />
      </label>
      <button onClick={handleValidate}>Validate JSON</button>
      <div>{validationResult}</div>
      <textarea
        value={value}
        required={required}
        placeholder={placeholder}
        onChange={(e) => wordChanged(e.target.value)}
      />
    </Container>
  );
};

export default TextArea;
