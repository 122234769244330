import { Container } from "./style";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface TextFieldProps {
  value: string;
  required?: boolean;
  label: string;
  placeholder: string;
  tooltip?: string;
  tooltipDescription?: string;
  wordChanged: any;
  type?: "text" | "number";
}

const TextField = ({
  value,
  required,
  label,
  placeholder,
  wordChanged,
  tooltipDescription,
  tooltip,
  type = "text",
}: TextFieldProps) => {
  const updateWrite = (event: React.ChangeEvent<HTMLInputElement>) => {
    wordChanged(event.target.value);
    // console.log(event.target.value)
  };

  // console.log(updateWrite)
  return (
    <Container>
      <label>
        {label}
        <span
          data-tooltip-id={tooltip}
          style={{
            cursor: "pointer",
          }}
        >
          (?)
        </span>
        <ReactTooltip
          id={tooltip}
          style={{
            marginLeft: "250px",
          }}
          place="top"
          variant="dark"
          content={tooltipDescription}
        />
      </label>
      <input
        type={type}
        value={value}
        onChange={updateWrite}
        required={required}
        placeholder={placeholder}
      />
    </Container>
  );
};

export default TextField;
