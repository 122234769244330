import { useState, useEffect } from "react";
import { Container, Forms } from "./style";
import Dropdown from "../../components/Dropdown";
import axios from "axios";
import TextField from "../../components/TextField";
import Button from "../../components/Button";

const Pricing = () => {
  const [items, setItems] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.moto-demomode-cloud.com/posts"
        );
        const data = response.data;
        const prod = data.map((each) => `${each.product}`);
        setProductNames(prod);
      } catch (error) {
        console.error("Error getting products:", error);
      }
    };

    fetchData();
  }, []);

  const data = Object.values(items);
  console.log(data);

  return (
    <Container>
      <h3>Pricing</h3>
      <Forms>
        <div className="forms">
          <h2>Insert New Price</h2>
          <Dropdown
            label="Model"
            items={productNames}
            value={selectedProduct}
            required={true}
            wordChanged={(value) => setSelectedProduct(value)}
          />
          <TextField placeholder="Insert price" label="Price"></TextField>
          <Button>Register</Button>
        </div>
      </Forms>
    </Container>
  );
};

export default Pricing;
