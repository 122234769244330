import "./style";
import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Logo, Head, User, Bar } from "./style";

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("Error signing out: ", error);
  }
}
interface UserInfo {
  username: string | null;
  email: string | null;
  groups: string[] | null;
}

const HeaderSidebar = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>({
    username: null,
    email: null,
    groups: null,
  });

  useEffect(() => {
    async function fetchUserInfo() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // console.log(user);
        setUserInfo({
          username: user.username,
          email: user.attributes.email,
          groups: user.signInUserSession.accessToken.payload["cognito:groups"],
        });
        console.log(userInfo);
      } catch (error) {
        console.log("Error fetching user info:", error);
      }
    }

    fetchUserInfo();
  }, []);

  return (
    <div>
      <NavLink className="NavLink" to="/">
        <Logo>
          <img className="logo" src="../../images/motorola.png" alt="logo" />
          <img
            className="back"
            src="../../images/arrow-point-to-right.png"
            alt="logo"
          />
        </Logo>
      </NavLink>
      <Head>
        <p>Demo Mode</p>
      </Head>
      <User>
        {userInfo ? (
          <div>
            <p>{userInfo.email}</p>
            <p>{userInfo.groups}</p>
          </div>
        ) : null}
        <NavLink className="NavLink" to="/" onClick={signOut}>
          <img className="exit" src="../../images/exit.png" alt="exit" />
        </NavLink>
      </User>
      <Bar />
    </div>
  );
};

export default HeaderSidebar;
