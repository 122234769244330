import "./App.css";
import { GlobalStyle } from "./styles/global";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import aws_exports from "./aws-exports";
import { AwsRum } from "aws-rum-web";

import Sidebar from "./components/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ListProduct from "./pages/ListProduct";
import NewAssets from "./pages/NewAssets";
import NewCampaign from "./pages/NewCampaign";
import NewCarrier from "./pages/NewCarrier";
import NewProduct from "./pages/NewProduct";
import Home from "./pages/Home";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Authenticator,
  Heading,
  useAuthenticator,
  View,
  Button,
} from "@aws-amplify/ui-react";
import Core from "./pages/NewCore";
import PrivateRoute from "./config/PrivateRoute";
import Pricing from "./pages/Pricing";
import Unauthorized from "./pages/Unauthorized";
import PricingNewProduct from "./pages/PricingNewProduct";

Amplify.configure(aws_exports);

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.REACT_APP_GUESTROLEARN,
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  };

  const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID;
  const APPLICATION_VERSION = "1.0.0";
  const APPLICATION_REGION = "us-east-1";

  new AwsRum(
    APPLICATION_ID as string,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
const componentsAuth = {
  SignIn: {
    Header() {
      return (
        <Heading {...({} as any)}>
          <div className="boxSignIn">
            <img
              className="logoSignIn"
              src="images/motorola.png"
              alt="Logo Motorola"
            />
            <button
              className="amplify-button dashboardRedirect"
              data-fullwidth="false"
              type="button"
              onClick={() =>
                (window.location.href =
                  "https://us-east-1.quicksight.aws.amazon.com/sn/start/analyses")
              }
            >
              <span className="amplify-text">Dashboard</span>
            </button>
          </div>
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();
      function handleOnChange(value: string | null) {
        console.log("Captcha value: ", value);
      }

      if (!handleOnChange) {
        console.log("Invalid");
      }

      return (
        <View textAlign="center">
          <div className="boxSignIn">
            <ReCAPTCHA
              sitekey="6LcZTAAmAAAAAPfxdoo8E14r5hvHsQrllFCRvqAG"
              onChange={handleOnChange}
            />
          </div>
          <Button
            placeholder="Button"
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            fontWeight="normal"
            onClick={toResetPassword}
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },
};

const PricingWithAuth = PrivateRoute(Pricing, ["Manager", "Stores"]);
const PricingNewProductWithAuth = PrivateRoute(PricingNewProduct, [
  "Manager",
  "Stores",
]);
const NewProductWithAuth = PrivateRoute(NewProduct, ["Manager"]);
const ListProductWithAuth = PrivateRoute(ListProduct, [
  "Manager",
  "Viewer",
  "Developer",
]);
const CarrierWithAuth = PrivateRoute(NewCarrier, ["Manager", "Developer"]);
const CampaignWithAuth = PrivateRoute(NewCampaign, ["Manager", "Developer"]);
const CoreWithAuth = PrivateRoute(Core, ["Manager", "Developer"]);
const AssetsWithAuth = PrivateRoute(NewAssets, ["Manager", "Developer"]);

export default function App() {
  return (
    <Authenticator
      socialProviders={["google"]}
      // formFields={formFields}
      components={componentsAuth}
      hideSignUp={true}
    >
      {({ signOut, user }) => (
        <main>
          <GlobalStyle />
          <div className="App">
            <BrowserRouter>
              <Sidebar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/new-product" element={<NewProductWithAuth />} />
                <Route path="/list-product" element={<ListProductWithAuth />} />
                <Route path="/new-carrier" element={<CarrierWithAuth />} />
                <Route path="/new-campaign" element={<CampaignWithAuth />} />
                <Route path="/pricing" element={<PricingWithAuth />} />
                <Route path="/new-assets" element={<AssetsWithAuth />} />
                <Route path="/new-core" element={<CoreWithAuth />} />
                <Route
                  path="/pricing-new-product"
                  element={<PricingNewProductWithAuth />}
                />
                <Route path="/unauthorized" element={<Unauthorized />} />
              </Routes>
            </BrowserRouter>
          </div>
          {/* <button onClick={signOut}>Sign out</button> */}
        </main>
      )}
    </Authenticator>
  );
}
