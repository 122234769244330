import { Container } from "./style";

const Dropdown = (props) => {
  const updateWrite = (event) => {
    props.wordChanged(event.target.value);
    // console.log(event.target.value)
  };

  return (
    <Container>
      <label>{props.label}</label>
      <select
        onChange={updateWrite}
        value={props.value}
        required={props.required}
        id={props.id}
      >
        <option value="" disabled hidden className="placeholder-option">
          {props.placeholder || ""}{" "}
        </option>
        {props.items.map((item) => {
          return <option key={item}>{item}</option>;
        })}
      </select>
    </Container>
  );
};

export default Dropdown;
