import styled from "styled-components";

export const Container = styled.div`
  margin-top: 18px;
  label {
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 20px;
  }

  textarea {
    min-width: 550px;
    min-height: 150px;
    max-width: 100%;
    border: 2px solid rgba(46, 46, 46, 0.15);
    font-size: 20px;
    padding: 14px 14px 14px 25px;
    border-radius: 0.75rem;
    resize: none;
  }

  @media screen and (max-width: 1366px) {
    textarea {
      min-width: 100%;
    }
  }
  @media screen and (min-width: 1921px) {
    textarea {
      min-width: 550px;
    }
  }
  textarea:focus {
    outline-color: rgba(0, 0, 0, 0.5);
  }

  textarea::placeholder {
    color: #d2d2d2;
  }
`;
