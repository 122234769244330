import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  label {
    min-width: 550px;
    min-height: 40px;
    border: 2px solid rgba(46, 46, 46, 0.15);
    border-radius: 1rem;
    padding: 14px 14px 14px 25px;
    background: #fff;
    transition: 0.5s;
    cursor: pointer;
  }

  @media screen and (max-width: 1366px) {
    label {
      min-width: 100%;
    }
  }
  @media screen and (min-width: 1921px) {
    label {
      min-width: 550px;
    }
  }
  input[type="file"] {
    display: block;
  }
  input::-webkit-file-upload-button,
  input::file-selector-button {
    width: 0px;
    overflow: hidden;
    content: "";
    padding: 0;
    font-size: 0;
    border: none;
    height: 40px;
    box-shadow: none;
    align-items: center;
    align-self: center;
    align-content: center;
  }
  label:hover {
    opacity: 0.3;
  }
`;
